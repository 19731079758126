<template>
    <el-container>
        <el-button type="primary" @click="show">主要按钮</el-button>
        <!-- <login :bg="false" :logo="false" class="sign" v-show="sign"></login> -->
    </el-container>
</template>

<script>
import Login from '@/view/login'

export default {
    name: 'sign',
    data() {
        return {
            sign: false
        }
    },
    components: {
        Login,
    },
    created() {
        // this.open()
    },
    methods: {
        show() {
            this.sign = true
        },
        open() {
            this.$alert('<strong>这是 <i>HTML</i> 片段</strong>',{
                showClose: false,
                showConfirmButton: false,
                // closeOnPressEscape: true,
                dangerouslyUseHTMLString: true
            });
        }
    }
}
</script>

<style lang="less" scoped>
.sign{
    background-color: rgba(0,0,0,0.3) !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}
</style>